<div class="page-title-area item-bg-8">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>{{'LOAN_DETAIL' | translate}}</h2>
          <ul>
            <li><a routerLink="/">{{'HOMEPAGE' | translate}}</a></li>
            <li>{{'LOAN_DETAIL' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="services-details-area ptb-100">
  <div class="container">
    <div class="row">

      <div class="col-lg-12 col-md-12">
        <div class="services-details-desc">
          <div class="content">
            <h3>{{loan?.title}}</h3>
            <p [innerHTML]="loan?.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
