<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{'LOAN_CALCULATOR' | translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{'HOMEPAGE' | translate}}</a></li>
                        <li>{{'LOAN_CALCULATOR' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="loan-calculator ptb-100">
    <div class="container">
        <div class="calculator-form">
            <div class="text">
                <span>{{'LOAN_CALCULATOR' | translate}}</span>
                <h3>{{'LOAN_CALCULATOR_OFFER' | translate}}</h3>
            </div>

            <div class="form-group mb-3">
                <label>{{'LOAN_AMOUNT' | translate}}</label>
                <input [(ngModel)]="loanAmount" [value]="loanAmount" (keyup)="loanAmountChanged($event.target.value)"
                       type="number" class="form-control">
            </div>
            <div class="form-group mb-3">
                <label>{{'LOAN_PERCENT' | translate}}</label>
                <input [(ngModel)]="loanPercent" [value]="loanPercent" (keyup)="loanPercentChanged($event.target.value)"
                       type="number" step="0.5" class="form-control">
            </div>
            <div class="form-group mb-3">
                <label>{{'LOAN_DURATION' | translate}} ({{'LOAN_DURATION_TYPE' | translate}})</label>
                <input [(ngModel)]="loanDuration" [value]="loanDuration"
                       (keyup)="loanDurationChanged($event.target.value)" type="number" class="form-control">
            </div>

            <div class="calculator-btn">
                <button (click)="calculate()" class="btn-one">{{'SHOW_CALCULATED' | translate}}</button>
                <!--                <a routerLink="/loan-calculator" class="btn-two">Download schedule</a>-->
            </div>
        </div>
    </div>
</section>

<section *ngIf="showTable" class="table-area pb-100">
    <div class="container">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>№</th>
                    <th>Ödəniş tarixi</th>
                    <th>Əsas məbləğ</th>
                    <th>Faiz məbləği</th>
                    <th>Ödəniləcək məbləğ</th>
                    <th>Qalıq borc</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of pagedPayments; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ payment.paymentDate | date:'dd/MM/yyyy' }}</td>
                    <td>{{ payment.principalAmount | currency:'AZN ':'symbol':'1.2-2' }}</td>
                    <td>{{ payment.interestAmount | currency:'AZN ':'symbol':'1.2-2' }}</td>
                    <td>{{ payment.paymentAmount | currency:'AZN ':'symbol':'1.2-2' }}</td>
                    <td>{{ payment.remainingBalance | currency:'AZN ':'symbol':'1.2-2' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <br>
        <ul class="pagination">
            <li class="page-item" (click)="setPage(1)">
                <a class="page-link" aria-label="First">
                    <span aria-hidden="true">&laquo;&laquo;</span>
                </a>
            </li>
            <li class="page-item" (click)="prevPage()" [class.disabled]="currentPage === 1">
                <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li class="page-item" *ngFor="let page of pages" (click)="setPage(page)" [class.active]="currentPage === page">
                <a class="page-link">{{ page }}</a>
            </li>
            <li class="page-item" (click)="nextPage()" [class.disabled]="currentPage === totalPages">
                <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
            <li class="page-item" (click)="setPage(totalPages)">
                <a class="page-link" aria-label="Last">
                    <span aria-hidden="true">&raquo;&raquo;</span>
                </a>
            </li>
        </ul>
    </div>



</section>
