<div class="page-title-area item-bg-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{'RETURNING' | translate}}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>{{'RETURNING' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-of-condition-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="terms-of-condition-content">
                    <h3>Müştərilərlə qarşılıqlı əlaqələr və kredit ödəniş kanalları haqqında məlumat</h3>
                  <p>&nbsp;</p>

                  <div class="learn-more-btn-section" style="box-sizing: border-box; margin: 0px; padding: 0px; display: inline-block; color: rgb(45, 46, 46); font-family: Roboto, sans-serif; font-size: 15px;">
                    <p>Müştərilərlə qarşılıqlı əlaqələr və kredit ödəniş kanalları haqqında məlumat</p>

                    <p>&nbsp; &nbsp; Kredit bazarında aparıcı təşkilatlardan olan <strong>&quot;Caspian İnvest&rdquo;</strong>, müştərilərinə daha operativ xidmət göstərəcək, onlara BOKT-a gəlmədən belə müxətlif &ccedil;eşidli BOKT məhsul və xidmətlərinindən yararlandığı kimi kredit ödənişlərini də daha asan və operativ təşkilini aşağıdakı kimi təmin edir.</p>

                    <p>&Ouml;dəniş zamanı &ouml;dəniş kanallarından Terminallar şəbəkəsi -</p>

                    <p>E-manat,</p>

                    <p>Milli&Ouml;n,</p>

                    <p>ExpressPay,</p>

                    <p>Kassam Az,</p>

                    <p>Kapital Bank terminallar şəbəkəsi.<br />
                      MobilBank tətbiqləri və İnternet Bankinq vasitəsilə &ouml;dəniş şəbəkəsi-Bİr Bank, E-Pul, Portmanat, CIB tətbiqlər.<br />
                      Banka Vaistəsi ilə -&nbsp; <em><strong>AZ90AIIB35060019440157910112</strong></em> saylı hesaba &ouml;dəniş edə bilərlər</p>
                  </div>
                </div>
            </div>

        </div>
    </div>
</section>
