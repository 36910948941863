<div class="page-title-area item-bg-9">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>{{ 'CONTACT' | translate }}</h2>
          <ul>
            <li><a routerLink="/">{{ 'HOMEPAGE' | translate }}</a></li>
            <li>{{ 'CONTACT' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="contact-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>{{ 'CONTACT_US_TITLE' | translate }}</h2>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div *ngIf="success" class="alert alert-success mt-3" role="alert">
          {{ 'SUCCESS_SENT' | translate }}
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
          {{ 'SUCCESS_ERROR' | translate }}
        </div>
        <div class="contact-form">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-group mb-3">
                  <label>{{ 'NAME' | translate }} {{ 'SURNAME' | translate }}</label>
                  <input [ngClass]="{ 'has-error': isInvalid('name') }" type="text" formControlName="name"
                         class="form-control" required>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="form-group mb-3">
                  <label>{{ 'EMAIL' | translate }}</label>
                  <input [ngClass]="{ 'has-error': isInvalid('email') }" type="email" formControlName="email"
                         class="form-control" required>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group mb-3">
                  <label>{{ 'MESSAGE' | translate }}</label>
                  <textarea [ngClass]="{ 'has-error': isInvalid('message') }" formControlName="message"
                            class="form-control" cols="30" rows="5" required></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <button type="submit" class="default-btn" [disabled]="!contactForm.valid">{{ 'SEND' | translate }}
                  <span></span></button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="contact-side-box">
          <div class="info-box">
            <div class="icon">
              <i class="flaticon-clock"></i>
            </div>
            <h4>{{ 'WORK_HOURS' | translate }}</h4>
            <ul class="list">
              <li>Mon – Thu <span>8:00 AM - 5:00 PM</span></li>
              <li>Fri – Sat <span>8:00 AM - 3:00 PM</span></li>
            </ul>
          </div>
          <div class="info-box">
            <div class="icon">
              <i class="flaticon-pin"></i>
            </div>
            <h4>{{ 'ADDRESS' | translate }}</h4>
            <span>{{ 'ADDRESS_LOCATION' | translate }}</span>
          </div>
          <div class="info-box">
            <div class="icon">
              <i class="flaticon-phone-call"></i>
            </div>
            <h4>{{ 'PHONE' | translate }}</h4>
            <span><a href="tel:994123111519">+994 123111519</a></span>
          </div>
          <div class="info-box">
            <div class="icon">
              <i class="flaticon-email"></i>
            </div>
            <h4>{{ 'EMAIL' | translate }}</h4>
            <span><a href="mailto:info@caspianinvest.az">info@caspianinvest.az</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="map">
  <div class="container-fluid">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.956047745666!2d49.86131997650677!3d40.409824456058914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d541efeca47%3A0x8f476f39574b596d!2zMTU2YSDGj2htyZlkIFLJmWPJmWJsaSwgQmFrxLEgMTA3NQ!5e0!3m2!1saz!2saz!4v1700944297379!5m2!1saz!2saz"></iframe>
  </div>
</div>
