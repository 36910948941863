<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{'NEWS' | translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{'HOMEPAGE' | translate}}</a></li>
                        <li>{{'NEWS' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <app-news></app-news>
    </div>
</section>
