import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {Subscription} from "rxjs";
import {LanguageService} from "../../../services/language.service";

@Component({
    selector: 'app-services-details',
    templateUrl: './services-details.component.html',
    styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

    loan: any;
    api = inject(ApiService)
    languageService = inject(LanguageService)
    languageSubscription: Subscription;

    constructor(private route: ActivatedRoute) {
        this.languageSubscription = this.languageService.currentLanguage$.subscribe(() => {
            this.getLoanById();
        });
    }

    ngOnInit(): void {
        this.getLoanById();
    }

    getLoanById() {
        this.route.paramMap.subscribe((params) => {
            const newsId = params.get('id');
            this.api.getLoanById(newsId).subscribe((data) => {
                this.loan = data.data;
            });
        });
    }

}
