import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  api = inject(ApiService);
  success: boolean = false;
  error: boolean = false;
  formSubmitted = false;

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.contactForm.valid) {

      const formData = this.contactForm.value;

      this.api.postContactUs(formData)
        .subscribe((response) => {
          this.success = true;
          this.error = false;
          setTimeout(() => {
            this.success = false;
          }, 4000);
        }, (error) => {
          this.success = false;
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 4000);
        });
      console.log('Form Data:', formData);
    } else {

    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.contactForm.get(controlName);
    return control && control.invalid && (control.dirty || control.touched || this.formSubmitted);
  }
}
