import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {Subscription} from "rxjs";
import {LanguageService} from "../../../services/language.service";

@Component({
    selector: 'app-loans',
    templateUrl: './loans.component.html',
    styleUrls: ['./loans.component.scss']
})
export class LoansComponent implements OnDestroy {
    api = inject(ApiService)
    languageService = inject(LanguageService)
    loanList: any;
    languageSubscription: Subscription;

    constructor() {
        this.languageSubscription = this.languageService.currentLanguage$.subscribe(() => {
            this.getLoanList();
        });
    }

    ngOnInit(): void {
        this.getLoanList();
    }

    getLoanList() {
        this.api.getLoanList().subscribe(data => {
            this.loanList = data?.data;
        })
    }

    ngOnDestroy(): void {
        this.languageSubscription.unsubscribe();
    }

}
