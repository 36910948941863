import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../../services/language.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  selectedLanguage: string = '';
  constructor(private translate: TranslateService, private languageService: LanguageService,) { }

  ngOnInit(): void {
    this.selectedLanguage = this.languageService.getLanguage();
    this.languageService.setLanguage(this.languageService.getLanguage());
  }

    protected readonly localStorage = localStorage;

  changeLanguage(lan: string) {
    localStorage.setItem('language', lan);
    this.selectedLanguage= lan;
    this.languageService.setLanguage(lan);
    this.translate.use(lan);
  }

}
