<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{'NEWS_DETAIL' | translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{'HOMEPAGE' | translate}}</a></li>
                        <li>{{'NEWS_DETAIL' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="projects-details ptb-100">
    <div class="container">
<!--        <div class="projects-details-image">-->
<!--            <img src="assets/img/single-projects.jpg" alt="image">-->
<!--        </div>-->

        <div class="projects-details-content">
            <h3>{{news?.title}}</h3>

            <p [innerHTML]="news?.content"></p>


<!--            <div class="projects-details-info">-->
<!--                <div class="single-info-box">-->
<!--                    <span>February 28, 2023</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</div>
