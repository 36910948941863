import {Component, OnInit} from '@angular/core';
import {LoanResult} from "../../models/loan-result.model";

@Component({
  selector: 'app-loan-calculator',
  templateUrl: './loan-calculator.component.html',
  styleUrls: ['./loan-calculator.component.scss']
})
export class LoanCalculatorComponent implements OnInit {

  showTable: boolean = false;
  loanAmount: number;
  loanPercent: number;
  loanDuration: number;
  result: LoanResult[] = []

  monthlyAmount: 0

  itemsPerPage = 10; // Sayfa başına gösterilecek öğe sayısı
  currentPage = 1; // Şu anki sayfa
  totalPages = 0;
  displayedResult: any[] = []
  pagedPayments: any[] = [];
  pageCount: number[]


  get pages(): number[] {
    return Array.from({length: this.totalPages}, (_, i) => i + 1);
  }
  calculatePages() {
    this.totalPages = Math.ceil(this.payments.length / this.itemsPerPage);
    this.pageCount = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      const startIndex = (page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.displayedResult = this.result.slice(startIndex, endIndex);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.setPage(this.currentPage + 1);
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.setPage(this.currentPage - 1);
    }
  }


  constructor() {
  }

  ngOnInit(): void {
  }

  modRound(value, n) {
    var mult = Math.pow(10, n);
    return Math.round(value * mult) / mult;
  }

  Correct_Str(str) {
    var str = str.toString();
    str = str.split('.');

    if (str.length > 1) {
      var str_right = str[1];
      if (str_right.length < 2) str_right = str_right + "0";
      str = str[0] + "." + str_right;
    } else str = str[0] + ".00";
    return str;
  }

  calculate() {
    this.payments = []
    this.calculatePayments();
    this.calculatePages();
    this.setPage(1);
    this.result = [];
    this.displayedResult = [];

    if (this.loanAmount > 0 && this.loanPercent > 0 && this.loanDuration > 0) {
      this.showTable = true;
      const month_procent = this.loanPercent / 12 / 100;
      const moterize_ve_ustu = Math.pow((1 + month_procent), this.loanDuration);
      const formul_top = month_procent * moterize_ve_ustu;
      const formul_bottom = moterize_ve_ustu - 1;
      const formul_k = formul_top / formul_bottom;
      const ayliq_odenish = formul_k * this.loanAmount;

      let borcun_qaligi = this.loanAmount;

      for (let i = 0; i < this.loanDuration; i++) {
        const faiz_meblegi = (borcun_qaligi * month_procent);
        const esas_mebleg = ayliq_odenish - faiz_meblegi;

        borcun_qaligi -= esas_mebleg;

        this.result.push({
          mainAmount: this.Correct_Str(this.modRound(esas_mebleg, 2)),
          percentAmount: this.Correct_Str(this.modRound(faiz_meblegi, 2)),
          debtAmount: this.Correct_Str(this.modRound(borcun_qaligi, 2))
        });
      }

      if (this.result.length > 0) {
        this.showTable = true;
        this.displayedResult = this.result;
      }
    }
  }


  loanAmountChanged(event: any) {
    this.loanAmount = +event;
  }

  loanPercentChanged(event: any) {
    this.loanPercent = +event;
  }

  loanDurationChanged(event: any) {
    this.loanDuration = +event;
  }
  payments: any[] = [];
  calculatePayments() {
    const monthlyInterestRate = this.loanPercent / 12 /100;
    const monthlyPayment =
        (this.loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -this.loanDuration));

    let remainingBalance = this.loanAmount;

    for (let i = 0; i < this.loanDuration; i++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interestPayment;
      remainingBalance -= principalPayment;

      this.payments.push({
        paymentDate: this.addMonths(new Date(), i + 1),
        principalAmount: principalPayment,
        interestAmount: interestPayment,
        paymentAmount: monthlyPayment,
        remainingBalance: remainingBalance
      });
    }
  }

  setPage(page: number) {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.currentPage = page;
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.pagedPayments = this.payments.slice(startIndex, endIndex);
  }

  pageChanged(event: any) {
    this.setPage(event);
  }

  addMonths(date: Date, months: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  }


}
