<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="projects-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our projects</span>
            <h2>All the work that we do</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="projects-slider owl-carousel owl-theme">
            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image1.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Financial Planning</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image2.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Startup Funding</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image3.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Fund Management</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image4.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Investment Shares</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image1.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Financial Planning</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image2.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Startup Funding</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image3.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Fund Management</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image4.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Investment Shares</a></h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="best-services-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Knowledge of the market</span>
            <h2>Only the best professional services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="tab services-list-tab">
            <ul class="tabs-list">
                <li>
                    <i class="flaticon-agriculture"></i>
                    <span>Agricultural loan</span>
                </li>
                <li>
                    <i class="flaticon-personal"></i>
                    <span>Personal loan</span>
                </li>
                <li>
                    <i class="flaticon-loan-1"></i>
                    <span>Business loan</span>
                </li>
                <li>
                    <i class="flaticon-scholarship"></i>
                    <span>Education loan</span>
                </li>
                <li>
                    <i class="flaticon-loan-2"></i>
                    <span>House loan</span>
                </li>
                <li>
                    <i class="flaticon-loan-3"></i>
                    <span>Payday loan</span>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>About Agricultural loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Get financing for whatever you need now</h3>
                                <li>New home</li>
                                <li>Home improvement loan</li>
                                <li>Home renovation</li>
                                <li>Land purchase</li>
                            </ul>
                            <div class="services-tab-content left">
                                <h3>Agricultural loan - Eligibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-image1">
                                <img src="assets/img/services-tab2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>About Personal loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Get financing for whatever you need now</h3>
                                <li>New home</li>
                                <li>Home improvement loan</li>
                                <li>Home renovation</li>
                                <li>Land purchase</li>
                            </ul>
                            <div class="services-tab-content left">
                                <h3>Personal loan - Eligibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-image1">
                                <img src="assets/img/services-tab2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>About Business loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Get financing for whatever you need now</h3>
                                <li>New home</li>
                                <li>Home improvement loan</li>
                                <li>Home renovation</li>
                                <li>Land purchase</li>
                            </ul>
                            <div class="services-tab-content left">
                                <h3>Business loan - Eligibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-image1">
                                <img src="assets/img/services-tab2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>About Education loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Get financing for whatever you need now</h3>
                                <li>New home</li>
                                <li>Home improvement loan</li>
                                <li>Home renovation</li>
                                <li>Land purchase</li>
                            </ul>
                            <div class="services-tab-content left">
                                <h3>Education loan - Eligibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-image1">
                                <img src="assets/img/services-tab2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>About Home Loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Get financing for whatever you need now</h3>
                                <li>New home</li>
                                <li>Home improvement loan</li>
                                <li>Home renovation</li>
                                <li>Land purchase</li>
                            </ul>
                            <div class="services-tab-content left">
                                <h3>Home Loan - Eligibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-image1">
                                <img src="assets/img/services-tab2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>About Payday loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Get financing for whatever you need now</h3>
                                <li>New home</li>
                                <li>Home improvement loan</li>
                                <li>Home renovation</li>
                                <li>Land purchase</li>
                            </ul>
                            <div class="services-tab-content left">
                                <h3>Payday loan - Eligibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-image1">
                                <img src="assets/img/services-tab2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/1.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/2.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/3.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/4.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/5.png" alt="image">
            </div>
        </div>
    </div>
</div>