<div class="main-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <span>{{ 'WE_ARE_HERE_FOR' | translate }}</span>
                    <h1>{{ 'START_YOUR_BUSINESS' | translate }}</h1>
                    <p>{{ 'LOAN_ADV' | translate }}</p>
                </div>

                <div class="banner-social-buttons">
                    <ul>
                        <li><span>{{ 'FOLLOW_US' | translate }}</span></li>
                        <li><a href="https://www.instagram.com/caspianinvest/" target="_blank"><i
                                class="flaticon-instagram"></i></a></li>
                        <li><a href="https://www.facebook.com/profile.php?id=61553591457719" target="_blank"><i
                                class="flaticon-facebook"></i></a></li>
                        <li style="visibility: hidden"><a href="#" target="_blank"><i class="flaticon-twitter"></i></a>
                        </li>
                        <li style="visibility: hidden"><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="approvals-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="approvals-content">
                        <div class="icon">
                            <i class="flaticon-loan"></i>
                        </div>
                        <span>{{ 'QUICK' | translate }}</span>
                        <p>{{ 'LOAN_APPROVE' | translate }}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="approvals-content">
                        <div class="icon">
                            <i class="flaticon-satisfaction"></i>
                        </div>
                        <span>20,000</span>
                        <p>{{ 'CUSTOMERS_SATISFACTION' | translate }}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="approvals-content">
                        <div class="icon">
                            <i class="flaticon-document"></i>
                        </div>
                        <span>{{ 'NO_PREPAYMENT' | translate }}</span>
                        <p>{{ 'HIDDEN_FEES' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'WHY_PEOPLE_CHOOSE' | translate }}</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="process-item-copy">
                    <div class="icon">
                        <i class="flaticon-guarantee"></i>
                    </div>
                    <h3>{{ 'GUARANTEE' | translate }}</h3>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="process-item-copy">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                    </div>
                    <h3>{{ 'SPEED' | translate }}</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="process-item-copy">
                    <div class="icon">
                        <i class="flaticon-reliability"></i>
                    </div>
                    <h3>{{ 'RELIABILITY' | translate }}</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="process-item-copy">
                    <div class="icon">
                        <i class="flaticon-user-experience"></i>
                    </div>
                    <h3>{{ 'EXPERIENCE' | translate }}</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="company-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="company-image">
                    <img src="assets/img/company.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-5">
                <div class="company-content">
                    <h3>{{ 'OUR_COMPANY_VALUES' | translate }}</h3>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-idea"></i>
                        </div>
                        <h4>{{ 'INNOVATIVE' | translate }}</h4>
                        <p>{{ 'INNOVATIVE_DESC' | translate }}</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-talent"></i>
                        </div>
                        <h4>{{ 'PROFESSIONAL' | translate }}</h4>
                        <p>{{ 'PROFESSIONAL_DESC' | translate }}</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-key"></i>
                        </div>
                        <h4>{{ 'BIG_CHOOSE' | translate }}</h4>
                        <p>{{ 'BIG_CHOOSE_DESC' | translate }}</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-responsibility"></i>
                        </div>
                        <h4>{{ 'RESPONSIBLE' | translate }}</h4>
                        <p>{{ 'RESPONSIBLE_DESC' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'LOAN_TYPES_TITLE2' | translate }}</h2>
        </div>

        <app-loans></app-loans>
    </div>
</section>


<!--<section class="projects-area ptb-100">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span>Our projects</span>-->
<!--            <h2>All the work that we do</h2>-->
<!--            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>-->
<!--        </div>-->

<!--        <div class="projects-slider owl-carousel owl-theme">-->
<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image1.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Financial Planning</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image2.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Startup Funding</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image3.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Fund Management</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image4.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Investment Shares</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image1.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Financial Planning</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image2.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Startup Funding</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a href="#"><img src="assets/img/projects/image3.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a href="#">Fund Management</a></h3>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="projects-item">-->
<!--                <a routerLink="/project-details"><img src="assets/img/projects/image4.jpg" alt="image"></a>-->

<!--                <div class="content">-->
<!--                    <h3><a routerLink="/project-details">Investment Shares</a></h3>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<section class="clients-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'CLIENT_SAY' | translate }}</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="clients-item">
                    <div class="info">
                        <img src="assets/img/clients/image1.png" alt="image">
                        <h4>Sənan Bağırov</h4>
                        <span>İş adamı</span>

                    </div>
                    <p>İllərdir ki, Caspian Invest şirkətinin müştərisiyəm. Lombard kreditindən dəfələrlə yararlanmışam
                        və çox
                        məmnun qalmışam</p>
                    <div class="icon">
                        <i class="flaticon-right-quote"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="clients-item">
                    <div class="info">
                        <img src="assets/img/clients/image2.png" alt="image">
                        <h4>Nərgiz Ömərova</h4>
                        <span>Daimi müştəri</span>
                    </div>
                    <p>Biznesimi böyütmək üçün bir neçə dəfə Caspian Invest'ə müraciət etmişəm. Heyətin peşəkarlığı üst
                        səviyyədədir.</p>
                    <div class="icon">
                        <i class="flaticon-right-quote"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'PARTNERS' | translate }}</h2>
        </div>
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/barkod_electronics.png" alt="image">
            </div>

            <!--      <div class="partner-item">-->
            <!--        <img src="assets/img/partner/2.png" alt="image">-->
            <!--      </div>-->

            <!--      <div class="partner-item">-->
            <!--        <img src="assets/img/partner/3.png" alt="image">-->
            <!--      </div>-->

            <!--      <div class="partner-item">-->
            <!--        <img src="assets/img/partner/4.png" alt="image">-->
            <!--      </div>-->

            <!--      <div class="partner-item">-->
            <!--        <img src="assets/img/partner/5.png" alt="image">-->
            <!--      </div>-->
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'NEWS' | translate }}</h2>
        </div>
        <app-news></app-news>
    </div>
</section>
