<div class="page-title-area item-bg-3">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>{{ 'APPLY_NOW' | translate }}</h2>
          <ul>
            <li><a routerLink="/">{{ 'HOMEPAGE' | translate }}</a></li>
            <li>{{ 'APPLY_NOW' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="apply-area ptb-100">
  <div class="container">
    <div class="apply-title">
      <h3>{{ 'LOAN_FORM' | translate }}</h3>
    </div>
    <div *ngIf="success" class="alert alert-success mt-3" role="alert">
      {{ 'SUCCESS_SENT' | translate }}
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
      {{ 'SUCCESS_ERROR' | translate }}
    </div>
    <ng-form (submit)="createAppeal()" class="row">
      <div class="col-lg-6">
        <div class="apply-form">
          <div class="form-group mb-3">
            <label>{{ 'NAME' | translate }}</label>
            <input id="name" name="name" [(ngModel)]="data.name" required type="text" class="form-control">
          </div>

          <div class="form-group mb-3">
            <label>{{ 'SURNAME' | translate }}</label>
            <input id="surname" name="surname" [(ngModel)]="data.surname" required type="text" class="form-control">
          </div>

          <div class="form-group mb-3">
            <label>{{ 'FATHER_NAME' | translate }}</label>
            <input id="fatherName" name="fatherName" [(ngModel)]="data.fatherName" required type="text"
                   class="form-control">
          </div>

          <div class="form-group mb-3">
            <label><label>{{ 'PHONE' | translate }}</label></label>
            <input id="phoneNumber" name="phoneNumber" [(ngModel)]="data.phoneNumber" required type="text"
                   class="form-control">
          </div>

          <div class="form-group mb-3">
            <label><label>{{ 'SALARY' | translate }}</label></label>
            <input id="salary" name="salary" [(ngModel)]="data.salary" required type="text" class="form-control">
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="apply-form">
          <div class="form-group mb-3">
            <label><label>{{ 'PIN' | translate }}</label></label>
            <input id="pin" name="pin" [(ngModel)]="data.pin" required type="text" class="form-control">
          </div>

          <div class="form-group mb-3">
            <label>{{ 'ID_CARD_NUMBER' | translate }}</label>
            <input id="idCardNumber" name="idCardNumber" [(ngModel)]="data.idCardNumber" required type="text" class="form-control">
          </div>

          <div class="form-group mb-3">
            <label>{{ 'LOAN_DURATION' | translate }}</label>
            <input id="loanDuration" name="loanDuration" [(ngModel)]="data.loanDuration" required type="text" class="form-control">
          </div>

          <div class="form-group mb-3">
            <label><label>{{ 'WORK_PLACE' | translate }}</label></label>
            <input id="workPlace" name="workPlace" [(ngModel)]="data.workPlace" required type="text" class="form-control">
          </div>
        </div>
      </div>
    </ng-form>

    <div  class="apply-btn">
      <button (click)="createAppeal()" class="default-btn" type="submit">{{ 'SEND' | translate }}</button>
    </div>
  </div>
</section>
