<div class="page-title-area item-bg-8">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>{{'REPORT' | translate}}</h2>
          <ul>
            <li><a routerLink="/">{{'HOMEPAGE' | translate}}</a></li>
            <li>{{'REPORT' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="faq-area ptb-100">
    <div class="container">

        <div class="tab faq-list-tab">
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item active">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i>Hesabat</a>
                                <p class="accordion-content"><a target="_blank" href="https://share.caspianinvest.az/oshar.pdf">Audit hesabatı (PDF)</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
