<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Testimonials</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Testimonials</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="clients-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Clients words</span>
            <h2>What our clients say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="clients-item">
                    <div class="info">
                        <img src="assets/img/clients/image1.png" alt="image">
                        <h4>Markus Twain</h4>
                        <span>Client</span>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <div class="icon">
                        <i class="flaticon-right-quote"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="clients-item">
                    <div class="info">
                        <img src="assets/img/clients/image2.png" alt="image">
                        <h4>Jessica Molony</h4>
                        <span>CEO</span>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <div class="icon">
                        <i class="flaticon-right-quote"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/1.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/2.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/3.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/4.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/5.png" alt="image">
            </div>
        </div>
    </div>
</div>