<div class="page-title-area item-bg-7">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'CREDIT_KINDS' | translate }}</h2>
                    <ul>
                        <li><a routerLink="/">{{ 'HOMEPAGE' | translate }}</a></li>
                        <li>{{ 'CREDIT_KINDS' | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'LOAN_TYPES_TITLE' | translate }}</h2>
        </div>

        <app-loans></app-loans>
    </div>
</section>
