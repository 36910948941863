<div class="row">
    <div *ngFor="let news of newsList" class="col-lg-4 col-md-6">
        <div class="blog-item">
            <div class="image">
                <a routerLink="/news-details/{{news?.id}}">
                    <img src="https://share.caspianinvest.az/_390x260_{{news?.mainImageUrl}}" alt="CaspianInvest - sizin maliyyə dəniziniz BOKT bank olmayan kredit təşkilatı">
                </a>
            </div>
            <div class="content">
                <span>{{news?.createdAt | date:'dd.MM.yyyy'}}</span>
                <h3><a routerLink="/news-details/{{news?.id}}">{{news?.title}}</a></h3>
                <a routerLink="/news-details/{{news?.id}}" class="blog-btn">{{'LOAD_MORE' | translate}}</a>
            </div>
        </div>
    </div>

</div>
