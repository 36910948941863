import {Component, inject, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {LanguageService} from "../../../services/language.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-projects-details',
    templateUrl: './projects-details.component.html',
    styleUrls: ['./projects-details.component.scss']
})
export class ProjectsDetailsComponent implements OnInit {

    news: any;
    api = inject(ApiService)
    languageService = inject(LanguageService)
    languageSubscription: Subscription;

    constructor(private route: ActivatedRoute) {
        this.languageSubscription = this.languageService.currentLanguage$.subscribe(() => {
            this.getNewsById();
        });
    }

    ngOnInit(): void {
        this.getNewsById();
    }

    getNewsById() {
        this.route.paramMap.subscribe((params) => {
            const newsId = params.get('id');
            this.api.getNewsById(newsId).subscribe((data) => {
                this.news = data.data;
            });
        });
    }

}
