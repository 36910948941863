import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // private apiUrl = 'http://localhost:8081';
  private apiUrl = 'https://back.caspianinvest.az';

  constructor(private http: HttpClient) {}

  postAppeal(data: any) {
    return this.http.post(`${this.apiUrl}/public/appeal`, data);
  }

  postContactUs(data: any){
    return this.http.post(`${this.apiUrl}/public/contact-us`, data);
  }

  getLoanList() : Observable<any>{
      const headers = new HttpHeaders()
          .set('Accept-Language', localStorage.getItem('language'));
    return this.http.get(`${this.apiUrl}/public/loans?page=0&size=20`,{ headers: headers })
  }
  getNewsList() : Observable<any>{
      const headers = new HttpHeaders()
          .set('Accept-Language', localStorage.getItem('language'));
    return this.http.get(`${this.apiUrl}/public/news?page=0&size=100&sort=createdAt,desc`,
      { headers: headers })
  }

  getNewsById(newsId: string): Observable<any> {
    const headers = new HttpHeaders()
        .set('Accept-Language', localStorage.getItem('language'));
    return this.http.get(`${this.apiUrl}/public/news/${newsId}`,{ headers: headers })
  }

  getLoanById(loanId: string): Observable<any> {
    const headers = new HttpHeaders()
        .set('Accept-Language', localStorage.getItem('language'));
    return this.http.get(`${this.apiUrl}/public/loans/${loanId}`,{ headers: headers })
  }
}

