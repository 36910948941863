import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguageSubject = new BehaviorSubject<string>('az');
  currentLanguage$ = this.currentLanguageSubject.asObservable();

  setLanguage(language: string): void {
    this.currentLanguageSubject.next(language);
    localStorage.setItem('language', language);
  }

  getLanguage(): string {
    return localStorage.getItem('language') || 'az';
  }
}
