import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { LoanCalculatorComponent } from './components/pages/loan-calculator/loan-calculator.component';
import { ApplyNowComponent } from './components/pages/apply-now/apply-now.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { FundingComponent } from './components/pages/funding/funding.component';
import { LoansComponent } from './components/pages/loans/loans.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgOptimizedImage} from "@angular/common";
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { NewsComponent } from './components/pages/news/news.component';
import { LoanDetailComponent } from './components/pages/loan-detail/loan-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    NavbarComponent,
    FooterComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    ContactComponent,
    GalleryComponent,
    AboutComponent,
    TeamComponent,
    FaqComponent,
    LoanCalculatorComponent,
    ApplyNowComponent,
    TestimonialsComponent,
    ProjectsComponent,
    ProjectsDetailsComponent,
    SignInComponent,
    SignUpComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ErrorComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    FundingComponent,
    LoansComponent,
    BlogComponent,
    BlogDetailsComponent,
    NewsDetailsComponent,
    NewsComponent,
    LoanDetailComponent
  ],
  imports: [
    BrowserModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

