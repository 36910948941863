<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/CASPIAN%20INVESTLOGO14.png" alt="image"></a>
                    </div>
                    <p>{{ 'SLOGAN' | translate }}</p>
                    <ul class="social">
                        <li><b>{{ 'FOLLOW_US' | translate }}:</b></li>
                        <li><a href="https://www.instagram.com/caspianinvest/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="https://www.facebook.com/profile.php?id=61553591457719" target="_blank"><i class="flaticon-facebook"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{ 'QUICK_LINKS' | translate }}</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/about">{{ 'ABOUT_US' | translate }}</a></li>
                        <li><a routerLink="/contact">{{ 'CONTACT' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{ 'OTHERS' | translate }}</h3>

                    <ul class="quick-links">
<!--                        <li><a routerLink="/privacy-policy">{{ 'PRIVACY' | translate }}</a></li>-->
                        <li><a routerLink="/terms-conditions">{{ 'RETURNING' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{ 'CONTACT' | translate }}</h3>

                    <div class="info-contact">
                        <i class="flaticon-pin"></i>
                        <span>{{ 'ADDRESS_LOCATION' | translate }}</span>
                    </div>
                    <div class="info-contact">
                        <i class="flaticon-mail"></i>
                        <span><a href="mailto:info@caspianinvest.az">info@caspianinvest.az</a></span>
                    </div>

                    <div class="info-contact">
                        <i class="flaticon-telephone"></i>
                        <span><a href="tel:994123111519">+994 123111519</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-content">
            <p>© Caspian Invest - 2024</p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>
