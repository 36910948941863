<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'ABOUT_US' | translate }}</h2>
                    <ul>
                        <li><a routerLink="/">{{ 'HOMEPAGE' | translate }}</a></li>
                        <li>{{ 'ABOUT_US' | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="about-content">
                    <h3>Şirkətimiz haqqında məlumat</h3>
                    <p>Bank Olmayan Kredit Təşkilatı "Caspian İnvest” Məhdud Məsuliyyətli Cəmiyyəti "Caspian İnvest” adı ilə 28.09.2004-cü il tarixli 164-QB Saylı lisenziya əsasında fəaliyyətə başlayıb və  öz fəaliyyətini bir neçə fundamental istiqamətdə davam etdirir. BOKT pərakəndə biznes, kiçik və orta sahibkarlıq, böyük korporativ biznesi dəstəkləyir və müştəri xidmətləri keyfiyyətinin artırılmasına davamlı səy göstərir. Ölkədə kiçik və orta sahibkarlıq kreditlərinin artım dinamikası BOKT-un inkişafı üçün bir stimuldur.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="solution-area pb-100">
    <div class="container">
        <div class="solution-item">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="content">
                        <h3>{{ 'ABOUT_US_WE_ARE_HERE' | translate }}</h3>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="solution-info">
                        <i class="flaticon-telephone"></i>
                        <h3><a href="tel:994123111519">+994 123111519</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
