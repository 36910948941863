<div class="page-title-area item-bg-9">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Funding</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Funding</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="funding-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="funding-image">
                    <img src="assets/img/funding.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="funding-content">
                    <span>About funding</span>
                    <h3>A fast approach to business financing. Business funding made fast and simple.</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <strong>We Reimagined Fundraising.</strong>
                    <ul class="funding-list">
                        <li>
                            <span>(1)</span>
                            <b>Fast</b> Get a term sheet in 20 min
                        </li>
                        <li>
                            <span>(2)</span>
                            <b>Flexible</b> Our data driven investments range from $1K to $1M
                        </li>
                        <li>
                            <span>(3)</span>
                            <b>Fair</b> No equity or personal guarantee
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="choose-title">
                    <span>Transparent process</span>
                    <h2>Why people choose us</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="choose-image">
                    <img src="assets/img/choose.png" alt="image">
                    <a href="https://www.youtube.com/watch?v=qaHWDmFtBl0" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top1">
                            <div class="icon">
                                <i class="flaticon-guarantee"></i>
                            </div>
                            <h3>Guarantee</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top2">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3>Speed</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top3">
                            <div class="icon">
                                <i class="flaticon-reliability"></i>
                            </div>
                            <h3>Reliability</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top4">
                            <div class="icon">
                                <i class="flaticon-user-experience"></i>
                            </div>
                            <h3>Experience</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="works-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>How it works</span>
            <h2>The funds have helped us move our business forward</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="works-item">
                    <div class="icon">
                        <i class="flaticon-user"></i>
                    </div>
                    <h3>Connect Your <br> Accounts</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="works-item">
                    <div class="icon">
                        <i class="flaticon-reciever"></i>
                    </div>
                    <h3>Receive Your <br> Funding</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="works-item">
                    <div class="icon">
                        <i class="flaticon-growth"></i>
                    </div>
                    <h3>Grow Your <br> Business</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="works-shape">
        <div class="shape"><img src="assets/img/works-shape.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/works-shape2.png" alt="image"></div>
    </div>
</section>

<section class="funding-form-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Apply for funding</span>
            <h2>Get funded from the comfort of your home</h2>
        </div>

        <div class="row">
            <div class="funding-form">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group mb-3">
                            <label>Full Name*</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mb-3">
                            <label>Phone Number</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mb-3">
                            <label>Business Website</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mb-3">
                            <label>Work Email*</label>
                            <input type="text" class="form-control">
                        </div>    
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mb-3">
                            <label>Password</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mb-3">
                            <label>Monthly Revenue*</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="buy-checkbox-btn">
                    <div class="item">
                        <input class="inp-cbx" id="cbx" type="checkbox">
                        <label class="cbx" for="cbx">
                            <span>
                                <svg width="12px" height="10px" viewbox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                            </span>
                            <span>I have read and agree to Clearbanc’s <a routerLink="/privacy-policy">Privacy Policy</a> and  <a routerLink="/terms-conditions">Terms of Service</a></span>
                        </label>
                    </div>
                </div>

                <div class="funding-btn">
                    <a routerLink="/apply-now" class="default-btn">Get Funding<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>