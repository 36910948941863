<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sign Up</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="signup-area ptb-100">
    <div class="container">
        <div class="signup-form">
            <h3>Create your Account</h3>
            <form>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" placeholder="Username">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group mb-3">
                            <input type="email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group mb-3">
                            <input type="password" class="form-control" placeholder="Password">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">Keep Me Sign Up</label>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="send-btn">
                            <button type="#" class="default-btn">Sign Up Now<span></span></button>
                        </div>
                        <br>
                        <span>Already a registered user? <a routerLink="/sign-in">Signin!</a></span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>