<div class="row">
  <div *ngFor="let loan of loanList" class="col-lg-4 col-md-6">
    <div class="services-item">
      <div class="icon">
        <i class="flaticon-agriculture"></i>
      </div>
      <h3>{{loan?.title}}</h3>
<!--      <p [innerHTML]="loan?.content"></p>-->
      <a routerLink="/loan-details/{{loan.id}}" class="learn-btn">{{ 'LOAD_MORE' | translate }}</a>
    </div>
  </div>
</div>
