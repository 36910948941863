import {Component, inject, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {LanguageService} from "../../../services/language.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
    api = inject(ApiService)
    languageService = inject(LanguageService)
    newsList: any;
    languageSubscription: Subscription;

    constructor() {
        this.languageSubscription = this.languageService.currentLanguage$.subscribe(() => {
            this.getNewsList();
        });
    }

    ngOnInit(): void {
        this.getNewsList();
    }

    getNewsList() {
        this.api.getNewsList().subscribe(data => {
            this.newsList = data?.data;
        })
    }

    ngOnDestroy(): void {
        this.languageSubscription.unsubscribe();
    }

}
