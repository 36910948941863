<div class="navbar-area">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/CASPIAN%20LOGO%20SVG.svg" alt="image"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/CASPIAN%20LOGO%20SVG.svg" alt="image"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!--                        <li class="nav-item">-->
                        <!--                            <a href="javascript:void(0)" class=" nav-link">{{'HOMEPAGE' | translate}}</a>-->

                        <!--                            <ul class="dropdown-menu">-->
                        <!--&lt;!&ndash;                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'HOMEPAGE | translate'}}</a></li>&ndash;&gt;-->

                        <!--&lt;!&ndash;                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>&ndash;&gt;-->

                        <!--&lt;!&ndash;                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>&ndash;&gt;-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{'HOMEPAGE' | translate}}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/loan-calculator" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{'LOAN_CALCULATOR' | translate}}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{'CREDIT_KINDS' | translate}}</a>
                        </li>
                        <!--            <li class="nav-item">-->
                        <!--              <a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>-->

                        <!--              <ul class="dropdown-menu">-->
                        <!--                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">About</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Team</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Gallery</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/loan-calculator" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Loan Calculator</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/apply-now" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Apply Now</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>-->

                        <!--                <li class="nav-item">-->
                        <!--                  <a href="javascript:void(0)" class="nav-link">Projects <i class='bx bx-chevron-down'></i></a>-->

                        <!--                  <ul class="dropdown-menu">-->
                        <!--                    <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"-->
                        <!--                                            [routerLinkActiveOptions]="{exact: true}">Projects</a></li>-->

                        <!--                    <li class="nav-item"><a routerLink="/project-details" class="nav-link" routerLinkActive="active"-->
                        <!--                                            [routerLinkActiveOptions]="{exact: true}">Project Details</a></li>-->
                        <!--                  </ul>-->
                        <!--                </li>-->

                        <!--                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Sign In</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>-->

                        <!--                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"-->
                        <!--                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>-->
                        <!--              </ul>-->
                        <!--            </li>-->

                        <!--                        <li class="nav-item">-->
                        <!--                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>-->

                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->

                        <!--                        <li class="nav-item"><a routerLink="/funding" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Funding</a></li>-->
                        <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{'NEWS' | translate}}</a>
                        </li>
<!--                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"-->
<!--                                                [routerLinkActiveOptions]="{exact: true}">{{'ABOUT_US' | translate}}</a>-->
<!--                        </li>-->

                                    <li class="nav-item">
                                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">{{'ABOUT_US' | translate}}</a>

                                      <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">{{'ABOUT_US' | translate}}</a></li>

                                        <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">{{'REPORT' | translate}}</a></li>
                                      </ul>
                                    </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{'CONTACT' | translate}}</a>
                        </li>



                        <li *ngIf="localStorage.getItem('language') === 'en'" (click)="changeLanguage('en')"
                            class="nav-item"><a style="font-style: oblique" (click)="changeLanguage('az')" class="nav-link">AZ</a></li>
                        <li *ngIf="localStorage.getItem('language') === 'az'" (click)="changeLanguage('en')"
                            class="nav-item"><a style="font-style: oblique" (click)="changeLanguage('en')" class="nav-link">EN</a></li>
                    </ul>

                    <div class="others-options d-flex align-items-center">

                        <div class="option-item">
                            <div class="info">
                                <i class="flaticon-telephone"></i>
                                <span>{{'CALL_NOW' | translate}}</span>
                                <p><a href="tel:994123111519">+994 123111519</a></p>
                            </div>
                        </div>

                        <div class="option-item">
                            <a routerLink="/apply-now" class="default-btn">{{'APPLY_NOW' | translate}}<span></span></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
