import {Component, inject, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-apply-now',
  templateUrl: './apply-now.component.html',
  styleUrls: ['./apply-now.component.scss']
})
export class ApplyNowComponent implements OnInit {
   success: boolean = false;
   error: boolean = false;

  constructor() {
  }

  api = inject(ApiService)

  data = {
    name: '',
    surname: '',
    phoneNumber: '',
    salary: 0,
    fatherName: '',
    pin: '',
    idCardNumber: '',
    loanDuration: '',
    workPlace: ''
  };

  ngOnInit(): void {
  }

  createAppeal() {
    console.log(this.data)
    this.api.postAppeal(this.data)
      .subscribe((response) => {
        this.success = true;
        this.error = false;
        setTimeout(() => {
          this.success = false;
        }, 4000);
      }, (error) => {
        this.success = false;
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 4000);
      });
  }

}
